import React, { createContext, useEffect, useRef, useState } from "react";
import { IChargeUser } from "../types/orders";
import { GetUser } from "../../utils/AuthUtils";

export interface IUserContext {
    actualUser: null | IChargeUser;
    setActualUser: React.Dispatch<React.SetStateAction<null | IChargeUser>>;
}

export const UserContext = createContext<null | IUserContext>(null);

interface Props {
    children: React.ReactNode
}

export const UserProvider: React.FC<Props> = ({ children }) => {
    const [actualUser, setActualUser] = useState<null | IChargeUser>(null);

    const canGo = useRef(true);
    useEffect(() => {
        if (!!canGo && canGo.current === true) {
            canGo.current = false;

            const user = GetUser();
            if (!!user) {
                setActualUser({
                    username: user.sub,
                    email: user.email,
                    name: user.given_name,
                    surname: user.family_name,
                    role: user.pt_role,
                })
            }
        }
    }, []);

    const value: IUserContext = {
        actualUser,
        setActualUser
    };

    return (
        <UserContext.Provider
            value={value}
        >
            {children}
        </UserContext.Provider>
    );
};