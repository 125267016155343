import React, { useState, useContext, useEffect, useMemo, useRef } from "react";
import Select from "../ui/select/Select";
import { ITripsContext, TripsContext } from "../../context/TripContext";
import {
  ICheckRightStopOrderResult,
  ITrip,
  ITripTruckPlate,
  TripStatusEnum,
  TripStop,
} from "../../types/trips";
import InputText from "../ui/inputText/InputText";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import Button from "../ui/button/Button";
import useImageFlag from "../../utils/hooks/useImageFlag";
import {
  IMasterDataContext,
  MasterDataContext,
} from "../../context/MasterDataContext";
import { IUserContext, UserContext } from "../../context/UserContext";
import {
  AdvancedFiltersType,
  tripsService,
} from "../../api/tripsService/tripsService";
import Spinner from "../ui/spinner/Spinner";
import _debounce from "lodash/debounce";
import { customConfirmAlert } from "../../utils/functions/customConfirmAlert";
import { toast } from "react-toastify";
import SidebarTripsMap from "./SidebarTripsMap";
import SelectAsyncPreference from "../ui/selectAsyncPreference/SelectAsyncPreference";
import { ResultType } from "../ui/selectTextInputAsync/SelectTextInputAsync";
import { masterDataService } from "../../api/masterDataService/masterDataService";
import dayjs from "dayjs";
import usePermissions from "../../utils/hooks/usePermissions";
import { PermissionAreas, PermissionOperations } from "../../types/permissions";
import AdvancedFilter from "../advancedFilter/AdvancedFilter";
import IconCamionS from "./IconCamionS";
import IconCamionM from "./IconCamionM";
import ModalTripRules from "../modals/modalTripRules/ModalTripRules";
import ModalTripDependencies from "../modals/modalTripDependencies/ModalTripDependencies";
import ModalTripError from "../modals/modalTripError/ModalTripError";
import { Divider } from "@material-ui/core";
import SelectedTripsComponent from "./SelectedTripsComponent/SelectedTripsComponent";
import { ErrorColorsEnum } from "../../types/orders";

// const plateRegex = /^[ABCDEFGHJKLMNPRSTVWXYZ]{2}[0-9]{3}[ABCDEFGHJKLMNPRSTVWXYZ]{2}$/gm;

interface Action {
  value: string;
  label: string;
}

var utc = require("dayjs/plugin/utc");
dayjs.extend(utc);

type ErrorObjType = {
  description: boolean;
  equipment: boolean;
  transportMode: boolean;
};

type Props = {
  isOpen: boolean;
  callbackOpenCloseSidebar: () => void;
};

const transportModeOptions = [
  {
    value: 1,
    label: "Combi",
  },
  {
    value: 2,
    label: "Road",
  },
];

const SidebarTrips: React.FC<Props> = ({
  isOpen,
  callbackOpenCloseSidebar,
}) => {
  const {
    trips,
    activeTrip,
    activeTripId,
    setActiveTripId,
    tripFilterOptions,
    currentTripOptionValueRef,
    loadingTripsRef,
    querySearchTripRef,
    advancedFiltersRef,
    setAdvancedFiltersRef,
    setCurrentTripOptionValueRef,
    // setQuerySearchTripRef,
    refreshOrders,
    refreshTripWithParams,
    updateTripWithParamsAndPagination,
    updateTripWithCurrentParamsAndPagination,
    isVisibleSidebarTrips,
  } = useContext(TripsContext) as ITripsContext;

  const { actualUser } = useContext(UserContext) as IUserContext;

  const { hasPermission } = usePermissions();
  const [selectedTrips, setSelectedTrips] = useState<number[]>([]);

  const {
    equipments,
    carriers,
    updateCarriers,
    trucks,
    trailers,
    updateVehicles,
  } = useContext(MasterDataContext) as IMasterDataContext;
  const isInModify = useRef(false);

  // ---- START: Handle pagination and filters ---- //
  const tripsContainerRef = useRef<null | HTMLDivElement>(null);
  useEffect(() => {
    const listener = () => {
      if (!!tripsContainerRef.current) {
        const scrollTop = tripsContainerRef.current?.scrollTop;
        const offsetHeight = tripsContainerRef.current?.offsetHeight;
        const scrollHeight = tripsContainerRef.current?.scrollHeight - 1;

        if (scrollTop + offsetHeight >= scrollHeight) {
          updateTripWithParamsAndPagination();
        }
      }
    };

    if (!!tripsContainerRef.current) {
      if (tripsContainerRef && tripsContainerRef.current !== null) {
        tripsContainerRef.current.addEventListener("scroll", listener);
      }
    }

    return () => {
      if (!!tripsContainerRef.current) {
        tripsContainerRef.current.removeEventListener("scroll", listener);
      }
    };
  }, [trips, isVisibleSidebarTrips]);
  // ---- END: Handle pagination and filters ---- //

  const [isOpenModalTripRules, setIsOpenModalTripRules] = useState<boolean>(
    false
  );
  // const [tripDependenciesToShow, setTripDependenciesToShow] = useState<ITripDependencies | undefined>(undefined);

  // Set local current data for trip
  const [activeData, setActiveData] = useState<null | ITrip>(null);
  useEffect(() => {
    setActiveData(activeTrip);
    isInModify.current = false;

    if (activeTrip === null) {
      setIsOpenTripPlanner(false);
    }
  }, [activeTrip]);

  // Find if user can edit data
  const canEditSelectedTrip = useMemo(() => {
    return (
      hasPermission(PermissionAreas.Planning, PermissionOperations.Modify) &&
      !!actualUser &&
      actualUser.username === activeTrip?.owner?.username &&
      (activeTrip?.status?.id === TripStatusEnum.NOT_DISPATCHABLE ||
        activeTrip?.status?.id === TripStatusEnum.REPLANNING)
    );
  }, [actualUser, activeTrip]);


  const isUserInCharge = useMemo(() => {
    return actualUser?.username === activeTrip?.owner?.username;
  }, [actualUser, activeTrip]);

  const [isOpenTripPlanner, setIsOpenTripPlanner] = useState(false);
  const handleClose = () => {
    if (!(isOpenTripPlanner && isInModify.current)) {
      setIsOpenTripPlanner(false);
      callbackOpenCloseSidebar();
    }
  };

  const [isMapFullScreen, setIsMapFullScreen] = useState<boolean>(false);
  useEffect(() => {
    setIsMapFullScreen(false);
  }, [isOpen, isOpenTripPlanner]);

  const handleOpenCloseTripPlanner = () => {
    if (!!activeTripId) {
      setIsOpenTripPlanner(!isOpenTripPlanner);
    }
  };

  const containerActiveTrip = useRef<null | HTMLDivElement>(null);

  // EquipmentOptions for Select
  const equipmentsOptions = useMemo(() => {
    return equipments.map((e) => {
      return {
        value: e.id,
        label: e.name,
      };
    });
  }, [equipments]);

  /** ---- START MANAGE UPDATE TRIPS ---- */
  const handleChangeTripOptionValue = async (e: number | string) => {
    const option = tripFilterOptions.find(
      (tO) => tO.value === parseInt(e.toString())
    );
    setActiveTripId(null);
    if (!!option) {
      refreshTripWithParams(
        option.value,
        querySearchTripRef.current,
        advancedFiltersRef.current
      );
      setCurrentTripOptionValueRef(parseInt(e.toString()));
    }
  };

  // const filterTripsDebounced = _debounce(handleDebounceFn, 1000);
  // function handleDebounceFn(inputValue: string) {
  //     refreshTripWithParams(currentTripOptionValueRef.current, inputValue, advancedFiltersRef.current);
  // }
  // const handleChangeQuerySearch = (e: string) => {
  //     setActiveTripId(null);
  //     setQuerySearchTripRef(e);
  //     filterTripsDebounced(e);
  // };

  const handleChangeTripAdvancedFilters = async (
    filters: AdvancedFiltersType
  ) => {
    setActiveTripId(null);
    setIsSearchFiltersActive(false);

    if (!!filters.tripDate) {
      refreshTripWithParams(
        currentTripOptionValueRef.current,
        querySearchTripRef.current,
        filters
      );
      setAdvancedFiltersRef(filters);
    }
  };
  /** ---- END MANAGE UPDATE TRIPS ---- */

  //FUnzione per uso singolo
  const handleTripCharge = async (e: any, trip: ITrip) => {
    e.stopPropagation();
    e.preventDefault();

    if (
      trip.status?.id === TripStatusEnum.DISPATCHING ||
      trip.status?.id === TripStatusEnum.DEPARTED
    ) {
      toast.error(
        "You can't take in charge a trip that is dispatching or departed."
      );
      return;
    }

    // chiamata API
    if (trip.owner?.username !== actualUser?.username) {
      const response = await tripsService._takeCharge(
        [trip.id],
        "Taking charge",
        "Operation completed succesfully",
        "Error during this operation"
      );

      if (response.success) {
        updateTripWithCurrentParamsAndPagination();
      }
    } else {
      let wantToContinue = true;
      if (trip.legs?.some((x) => !x?.planned)) {
        wantToContinue = await customConfirmAlert({
          title: "Unconfirmed legs",
          message:
            "Unconfirmed legs will be confirmed. Do you want to continue?",
        });
      }

      if (wantToContinue && !!actualUser) {
        const response = await tripsService._revokeCharge(
          [trip.id],
          actualUser.username[0],
          true,
          "Revoking charge",
          "Operation completed succesfully",
          "Error during this operation"
        );
        if (response.success) {
          updateTripWithCurrentParamsAndPagination();
          refreshOrders();
        }
      }
    }
  };

  const _handleTripTakeCharge = async () => {
    
    // Controllo permessi
    if (!hasPermission(PermissionAreas.Planning, PermissionOperations.Modify)) {
      return toast.error("You do not have permission to take charge of trips.");
    }
  
    if (!trips || trips.length === 0) {
      return toast.error("No trips available.");
    }
  
    const validTrips = [];
    const invalidTrips = [];
  
    // Iteriamo sui trip selezionati per fare tutti i controlli necessari
    for (const tripId of selectedTrips) {
      try {
        const trip = trips.find((t) => t.id === tripId); // Recupera il trip dalla lista completa
  
        if (trip) {
          // Controllo sullo stato del trip
          if (
            trip.status?.id === TripStatusEnum.DISPATCHING ||
            trip.status?.id === TripStatusEnum.DEPARTED ||
            trip.owner !== null
          ) {
            invalidTrips.push(tripId); // Aggiungi alla lista dei trip non validi
            continue;
          }
  
          // Aggiungi l'ID del trip al vettore validTrips
          validTrips.push(tripId);
        }
      } catch (error) {
        const errorMessage = error instanceof Error ? error.message : "An unknown error occurred.";
       /*  toast.error(`Unexpected error during the operation for Trip ${tripId}: ${errorMessage}`); */
      }
    }
  
    // Se ci sono trip non validi, mostra un messaggio unico
    if (invalidTrips.length > 0) {
      toast.error(`The following trips are not valid and cannot be taken in charge: ${invalidTrips.join(", ")}`);
    }
  
    // Se ci sono trip validi, fai una chiamata API per prenderli in carico
    if (validTrips.length > 0) {
      try {
        const response = await tripsService._takeCharge(
          validTrips, // Passiamo gli ID dei trip validi
          "Taking charge",
          `Operation for Trips ${validTrips.join(", ")} completed successfully`,
          `Error during the operation for Trips ${validTrips.join(", ")}`
        );
  
        if (response.success) {
          // Aggiorna dopo la chiamata API riuscita
          updateTripWithCurrentParamsAndPagination();
        } else {
          toast.error(`Error during the operation for Trips: ${response.error.data.detail}`);
        }
      } catch (error) {
        const errorMessage = error instanceof Error ? error.message : "An unknown error occurred.";
        toast.error(`Unexpected error during the operation for Trips: ${errorMessage}`);
      }
    } else {
      toast.error("No valid trips to take charge.");
    }
  };
  

  const _handleTripRevokeCharge = async () => {
    // Controllo permessi
    if (!hasPermission(PermissionAreas.Planning, PermissionOperations.Modify)) {
      return toast.error("You do not have permission to revoke charge of trips.");
    }
  
    if (!trips || trips.length === 0) {
      return toast.error("No trips available.");
    }
  
    // Filtra solo i trip che hanno un owner assegnato (in carico a qualcuno) e sono definiti
    const tripsWithOwner = selectedTrips
      .map((tripId) => trips.find((t) => t.id === tripId))
      .filter((trip) => trip !== undefined && trip.owner); // Assicurati che il trip sia definito
  
    if (tripsWithOwner.length === 0) {
      return toast.error("No trips with an assigned owner to revoke charge.");
    }
  
    const validTrips = [];
    const invalidTrips = []; // Array per i trip non validi
  
    // Itera sui trip con owner assegnato per fare i controlli
    for (const trip of tripsWithOwner) {
      if (!trip) continue;
  
      try {
        // Controllo su leg non confermati
        if (trip.legs?.some((x) => !x?.planned)) {
          // Aggiungi il trip alla lista non valida
  
          // Mostra un alert personalizzato con i trip non validi
          const wantToContinue = await customConfirmAlert({
            title: "Unconfirmed legs",
            message: `Some trips has unconfirmed legs. Do you want to continue revoking charge? Invalid trips: ${invalidTrips.join(", ")}`,
          });
  
          // Se l'utente non vuole continuare, salta al prossimo trip
          if (!wantToContinue) {
            invalidTrips.push(trip.id);
            continue;
          }
        }
  
        // Aggiungi l'ID del trip al vettore validTrips
        validTrips.push(trip.id);
      } catch (error) {
        const errorMessage = error instanceof Error ? error.message : "An unknown error occurred.";
        toast.error(`Unexpected error during the operation for Trip ${trip.id}: ${errorMessage}`);
        // Aggiungi alla lista dei trip non validi
        invalidTrips.push(trip.id);
        continue;
      }
    }
  
    // Se ci sono trip non validi, mostra un messaggio unico
    if (invalidTrips.length > 0) {
      toast.error(`The following trips cannot be revoked: ${invalidTrips.join(", ")}`);
    }
  
    // Se ci sono trip validi, fai una chiamata API per revocare il charge
    if (validTrips.length > 0) {
      try {
        if (!!actualUser) {
          const response = await tripsService._revokeCharge(
            validTrips, // Chiamata per i trip validi
            actualUser.username,
            true,
            "Revoking charge",
            `Operation for Trips ${validTrips.join(", ")} completed successfully`,
            `Error during the operation for Trips ${validTrips.join(", ")}`
          );
  
          if (response.success) {
          
            // Aggiorna dopo la chiamata API riuscita
            updateTripWithCurrentParamsAndPagination();
            refreshOrders();
          } else {
            toast.error(`Some trips not in Charge: ${response.error.data.detail}`);
          }
        }
      } catch (error) {
        const errorMessage = error instanceof Error ? error.message : "An unknown error occurred.";
        toast.error(`Unexpected error during the operation for Trips: ${errorMessage}`);
      }
    } else {
      toast.error("No valid trips to revoke charge.");
    }
  };
  

  /** ----- START HANDLING DnD AND STOP ORDER CHECKS ----- */
  // Function to update list on drop
  const handleDrop = (droppedItem: any) => {
    if (!!activeData && !!trips) {
      // Ignore drop outside droppable container
      if (!droppedItem.destination) return;
      var updatedList = [...activeData.stops];
      // Remove dragged item
      const [reorderedItem] = updatedList.splice(droppedItem.source.index, 1);
      // Add dropped item
      updatedList.splice(droppedItem.destination.index, 0, reorderedItem);

      setActiveData({
        ...activeData,
        stops: applyStartStopList(updatedList),
      });

      isInModify.current = true;
    }
  };

  const [stopOrderIssues, setStopOrderIssues] = useState<string[]>([]);
  useEffect(() => {
    if (!activeData || activeData?.stops.length === 0) {
      return;
    }
    checkStopOrder();
  }, [activeData?.stops]);

  const checkStopOrder = async () => {
    if (!!activeTripId && !!activeData) {
      const response = await tripsService.checkStopOrder(activeTripId, {
        stops: JSON.parse(JSON.stringify(activeData.stops)).map(
          (tP: TripStop, index: number) => {
            return {
              ...tP,
              stopSequence: index + 1,
            };
          }
        ),
      });

      if (response.success) {
        setStopOrderIssues(createStopOrderIssuesList(response.data));
      }
    }
  };

  const createStopOrderIssuesList = (
    stopOrderResult: ICheckRightStopOrderResult
  ) => {
    let issuesList: string[] = [];

    if (!stopOrderResult.isCorrect) {
      stopOrderResult.issues.forEach((issue) => {
        let originStopName = activeData?.stops.find(
          (x) => x.id === issue.originStopId
        )?.address.invPartyName;
        let destinationStopName = activeData?.stops.find(
          (x) => x.id === issue.destinationStopId
        )?.address.invPartyName;
        issuesList.push(
          `${originStopName} must precede ${destinationStopName}`
        );
      });
    }

    return issuesList;
  };
  /** ----- START END DnD AND STOP ORDER CHECKS ----- */
  const _handleTripConfirmation = async () => {
    // Verifica che ci siano trips e che selectedTrips non sia vuoto
    if (!trips || trips.length === 0) {
      console.log("No trips available or trips array is empty:", trips);
      toast.info("No trip data available");
      return;
    }

    if (!selectedTrips || selectedTrips.length === 0) {
      console.log("No trips selected:", selectedTrips);
      toast.info("No trips selected");
      return;
    }

    // Verifica permessi su tutti i viaggi selezionati
    const unauthorizedTrips = selectedTrips.filter((tripId) => {
      const trip = trips.find((t) => t.id === tripId);

      // Controlla se il trip è modificabile o è in uno stato non confermabile
      return (
        !trip ||
        isInModify.current || // Se è in modalità modifica
        (trip.status && // Controlla lo stato del singolo trip
          trip.status.id !== TripStatusEnum.NOT_DISPATCHABLE &&
          trip.status.id !== TripStatusEnum.REPLANNING)
      );
    });

    if (unauthorizedTrips.length > 0) {
      toast.error("One or more Trips have errors.");
      console.log("Unauthorized trips:", unauthorizedTrips);
      return; // Interrompe l'esecuzione se ci sono viaggi non autorizzati
    }

    // Controlla che tutti i viaggi selezionati abbiano i campi richiesti
    const missingDataTrips = selectedTrips.filter((tripId) => {
      const trip = trips.find((t) => t.id === tripId);
      console.log("Checking trip:", trip);
      return (
        !trip || !trip.description || !trip.equipment || !trip.transportMode
      );
    });

    if (missingDataTrips.length > 0) {
      console.log("Trips with missing data:", missingDataTrips);
      toast.info("One or more trips have missing data");
      validateData();
      return;
    }

    const wantToContinueFirst = await customConfirmAlert({
      title: "Set as dispatchable",
      message:
        "Are you sure you want to set the trips as dispatchable? You will no longer be able to edit them.",
    });

    console.log(
      "User wants to continue after first confirmation:",
      wantToContinueFirst
    );

    if (wantToContinueFirst) {
      let wantToContinue = true;

      // Controlla se ci sono legs non confermati nei trip selezionati
      const unconfirmedLegsTrips = selectedTrips.filter((tripId) => {
        const trip = trips.find((t) => t.id === tripId);
        console.log("Checking legs for trip:", trip);
        return trip?.legs.some((x) => !x.planned);
      });

      console.log("Trips with unconfirmed legs:", unconfirmedLegsTrips);

      if (unconfirmedLegsTrips.length > 0) {
        wantToContinue = await customConfirmAlert({
          title: "Unconfirmed legs",
          message:
            "Some trips have unconfirmed legs. They will be confirmed. Do you want to continue?",
        });

        console.log(
          "User wants to continue after unconfirmed legs warning:",
          wantToContinue
        );
      }

      if (wantToContinue) {
        // Usa _checkConfirmTrip per passare i selectedTrips
        console.log("Sending confirmation for trips:", selectedTrips);
        const response = await tripsService._checkConfirmTrip(
          selectedTrips, // Passa la lista di ID dei viaggi selezionati
          "Confirming trips",
          "Trips confirmed successfully",
          "Error during trip confirmation"
        );

        console.log("Confirmation response:", response);

        if (response.success) {
          console.log("Trips confirmed successfully, refreshing data...");
          updateTripWithCurrentParamsAndPagination();
          refreshOrders();
        } else {
          console.log("Error during trip confirmation");
        }
      }
    }
  };

  //Funzione uso singolo
  const handleTripConfirmation = async (checked: boolean) => {
    // Set data
    if (
      !!!activeTrip ||
      !!!activeTrip.description ||
      !!!activeTrip.equipment ||
      !!!activeTrip.transportMode
    ) {
      toast.info("Missing trip data");
      validateData();
      return;
    }

    const wantToContinueFirst = await customConfirmAlert({
      title: "Set as dispatchable",
      message:
        "Are you sure you want to set the trip as dispatchable? You will no longer be able to edit the trip.",
    });

    if (wantToContinueFirst) {
      let wantToContinue = true;
      if (activeTrip?.legs.some((x) => !x.planned)) {
        wantToContinue = await customConfirmAlert({
          title: "Unconfirmed legs",
          message:
            "Unconfirmed legs will be confirmed. Do you want to continue?",
        });
      }

      if (wantToContinue) {
        const response = await tripsService._checkConfirmTrip(
          [activeTrip?.id],
          "Confirming trip",
          "Trip confirmed succesfully",
          "Error during trip confirmation"
        );
        if (response.success) {
          updateTripWithCurrentParamsAndPagination();
          refreshOrders();
        }
      }
    }
  };

  const handleChangeTripTruckTrailerPlate = (
    e: ResultType | null,
    type: "truck" | "trailer"
  ) => {
    // Set data
    if (!!activeData) {
      if (type === "truck") {
        let truckPlateNumber: ITripTruckPlate | null =
          e !== null ? { id: e.id, name: e.name } : null;
        setActiveData({
          ...activeData,
          truckPlateNumber,
        });
      } else {
        let truckPlateTrailer: ITripTruckPlate | null =
          e !== null ? { id: e.id, name: e.name } : null;
        setActiveData({
          ...activeData,
          truckPlateTrailer,
        });
      }

      isInModify.current = true;
    }
  };

  const handleChangeEquipment = (e: string) => {
    if (!!activeData) {
      setActiveData({
        ...activeData,
        // @ts-ignore
        equipment: equipments.find((eq) => eq.id === parseInt(e.toString())),
      });

      isInModify.current = true;
    }
  };

  const handleChangeCarrier = (e: ResultType | null) => {
    if (!!activeData) {
      setActiveData({
        ...activeData,
        carrier: e,
      });

      isInModify.current = true;
    }
  };

  const handleChangeTransportMode = (e: string | number) => {
    if (!!activeData) {
      setActiveData({
        ...activeData,
        // @ts-ignore
        transportMode: transportModeOptions.find(
          (tM) => tM.value == parseInt(e.toString())
        )?.label,
      });

      isInModify.current = true;
    }
  };

  const handleChangeTripDescription = (e: string) => {
    if (!!activeData) {
      setActiveData({
        ...activeData,
        description: e,
      });

      isInModify.current = true;
    }
  };

  const handleDeleteTrip = async () => {
    const wantToContinue = await customConfirmAlert({
      title: "Delete Trip",
      message: "Are you sure to remove this trip?",
    });

    if (wantToContinue) {
      deleteTrip();
    }
  };

  const deleteTrip = async () => {
    if (!!canEditSelectedTrip) {
      if (trips !== null) {
        if (!!activeData) {
          const response = await tripsService.deleteTrip(
            activeData.id,
            "Removing trip...",
            "Trip removed successfully",
            "Error removing trip"
          );
          if (response.success) {
            refreshTripWithParams(
              currentTripOptionValueRef.current,
              querySearchTripRef.current,
              advancedFiltersRef.current
            );
            setActiveTripId(null);
          }
        }
      }
    }
  };

  const [tripErrors, setTripErrors] = useState<ErrorObjType>({
    description: false,
    equipment: false,
    transportMode: false,
  });

  const validateData = () => {
    if (!!activeData) {
      let errorObj: ErrorObjType = {
        description: false,
        equipment: false,
        transportMode: false,
      };
      if (!!!activeData.description) {
        errorObj.description = true;
      }
      if (!!!activeData?.equipment) {
        errorObj.equipment = true;
      }
      if (!!!activeData?.transportMode) {
        errorObj.transportMode = true;
      }

      setTripErrors(errorObj);
    }
  };

  const resetTripError = (
    field: "description" | "equipment" | "transportMode"
  ) => {
    setTripErrors({
      ...tripErrors,
      [field]: false,
    });
  };

  const handleTripSave = async () => {
    // Validate Trip info
    if (actualUser?.username !== activeData?.owner?.username) {
      return;
    }

    if (
      !!!activeData ||
      activeTripId === null ||
      !!!activeData.description ||
      !!!activeData.equipment ||
      !!!activeData.transportMode
    ) {
      toast.info("Missing trip data");
      validateData();
      return;
    }

    const response = await tripsService.updateTrip(
      activeData.id,
      {
        header: {
          carrierId: !!activeData.carrier ? activeData.carrier.id : null,
          description: !!activeData.description ? activeData.description : "",
          equipmentId: activeData.equipment.id,
          truckId: !!activeData.truckPlateNumber
            ? activeData.truckPlateNumber.id
            : null,
          trailerId: !!activeData.truckPlateTrailer
            ? activeData.truckPlateTrailer.id
            : null,
          transportMode: activeData.transportMode,
        },
        stops: [...activeData.stops].map((tP, index) => {
          return {
            ...tP,
            stopSequence: index + 1,
          };
        }),
      },
      "Saving trip...",
      "Trip saved successfully",
      "Error saving trip"
    );

    if (response.success) {
      // update trips
      updateTripWithCurrentParamsAndPagination();
      isInModify.current = false;
    }
  };

  //Funzione per l'uso singolo
  const handleTripSendImmediately = async () => {
    if (!!activeTrip) {
      const wantToContinue = await customConfirmAlert({
        message: "Do you want to send the trip immediatly?",
        title: "Send immediatly",
      });

      if (wantToContinue) {
        const response = await tripsService._sendTripImmediately(
          [activeTrip.id],
          "Sending request...",
          "Trip submission request taken over",
          "Error sending request"
        );

        if (response.success) {
          // update trips
          updateTripWithCurrentParamsAndPagination();
          isInModify.current = false;
        }
      }
    }
  };

  //Funzione per le azioni massive
  const _handleTripSendImmediately = async () => {
    if (!trips || trips.length === 0) {
      console.log("No trips available or trips array is empty:", trips);
      toast.info("No trip data available");
      return;
    }
    if (selectedTrips.length > 0) {
      const selectedTripsDetails = trips.filter((trip) =>
        selectedTrips.includes(trip.id)
      );

      // Aggiungi controllo sullo stato del trip e permessi
      const isDispatchableAndAllowed = selectedTripsDetails.every(
        (trip) =>
          trip.status?.id === TripStatusEnum.DISPATCHABLE &&
          trip.sendImmediatly === false &&
          hasPermission(PermissionAreas.Planning, PermissionOperations.SendTrip)
      );

      if (!isDispatchableAndAllowed) {
        console.log(
          "One or more trips cannot be sent immediately or permissions are missing."
        );
        toast.warning(
          "You do not have permission to send these trips or the trips cannot be dispatched."
        );
        return;
      }

      const wantToContinue = await customConfirmAlert({
        message: "Do you want to send the selected trips immediately?",
        title: "Send Immediately",
      });

      if (wantToContinue) {
        const response = await tripsService._sendTripImmediately(
          selectedTrips,
          "Sending request...",
          "Trip submission request taken over",
          "Error sending request"
        );

        if (response.success) {
          // Aggiorna i viaggi se la richiesta ha avuto successo
          updateTripWithCurrentParamsAndPagination();
          isInModify.current = false;
        }
      }
    } else {
      console.log("No trips selected to send.");
    }
  };

  const checkSubmitSaveTrip = async (e: any) => {
    if (
      !!containerActiveTrip &&
      !!containerActiveTrip.current &&
      !containerActiveTrip.current.contains(e.target)
    ) {
      if (isOpen) {
        if (activeTrip !== null && isOpenTripPlanner && isInModify.current) {
          e.preventDefault();
          e.stopPropagation();
          (async () => {
            const wantToSave = await customConfirmAlert({
              message: "Do you want to save your trip changes?",
              title: "Close trip",
            });

            if (wantToSave) {
              await handleTripSave();
              setIsOpenTripPlanner(false);
            } else {
              setIsOpenTripPlanner(false);
            }
          })();
        }
      }
    }
  };

  const handleTripReplan = async () => {
    if (!!activeTripId) {
      let wantToContinue = await customConfirmAlert({
        title: "Replan trip",
        message: "Are you sure you want to replan the trip?",
      });

      if (wantToContinue) {
        const response = await tripsService.replanTrip(
          activeTripId,
          "Replanning trip...",
          "Trip replanned succesfully",
          "Error replanning trip"
        );

        if (response.success) {
          updateTripWithCurrentParamsAndPagination();
          refreshOrders();
          isInModify.current = false;
        }
      }
    }
  };

  type PropsPlanElement = {
    index: number;
    stage: TripStop;
    positionNumber: number;
  };

  const handleChangeStartStopTime = (
    e: string,
    stopIndex: number,
    type: "start" | "stop" | "startStop"
  ) => {
    if (!!activeData) {
      const copyStops: TripStop[] = JSON.parse(
        JSON.stringify(activeData.stops)
      );

      if (type === "start") {
        // @ts-ignore
        copyStops[stopIndex].startTime = dayjs.utc(new Date(e)).format("YYYY-MM-DDTHH:mm:ss");
      } else if (type === "stop") {
        // @ts-ignore
        copyStops[stopIndex].stopTime = dayjs.utc(new Date(e)).format("YYYY-MM-DDTHH:mm:ss");
      } else {
        // @ts-ignore
        copyStops[stopIndex].startTime = dayjs.utc(new Date(e)).format("YYYY-MM-DDTHH:mm:ss");
        // @ts-ignore
        copyStops[stopIndex].stopTime = dayjs.utc(new Date(e)).format("YYYY-MM-DDTHH:mm:ss");
      }

      const newActiveData = {
        ...activeData,
        stops: copyStops,
      };

      setActiveData(newActiveData);
      changeStartStopList(newActiveData);
      isInModify.current = true;
    }
  };

  // startStopList is a copy of the dates on activeData to mantain the original order of dates when the stops order is changed
  const [startStopsList, setStartStopsList] = useState<
    null | { startTime: null | string; stopTime: null | string }[]
  >(null);
  const canInitStartStopList = useRef(true);
  useEffect(() => {
    if (activeData !== null) {
      if (canInitStartStopList.current) {
        canInitStartStopList.current = false;
        changeStartStopList(activeData);
      }
    } else {
      canInitStartStopList.current = true;
    }
  }, [activeData]);

  const changeStartStopList = (data: ITrip) => {
    const copyActiveData: ITrip = JSON.parse(JSON.stringify(data));
    setStartStopsList(
      copyActiveData.stops.map((stop) => {
        return {
          startTime: stop.startTime,
          stopTime: stop.stopTime,
        };
      })
    );
  };

  const applyStartStopList: (stops: TripStop[]) => TripStop[] = (stops) => {
    const copyStops: TripStop[] = JSON.parse(JSON.stringify(stops));
    const modifiedStops = copyStops.map((stop, index) => {
      return {
        ...stop,
        startTime:
          !!startStopsList && !!startStopsList[index]
            ? startStopsList[index].startTime
            : null,
        stopTime:
          !!startStopsList && !!startStopsList[index]
            ? startStopsList[index].stopTime
            : null,
      };
    });

    return modifiedStops;
  };

    const getTripErrorColor = (errorColor: string | null | undefined) => {
        if (!!!errorColor) {
            return "text-red";
        }

        if (errorColor === ErrorColorsEnum.RED) {
            return "text-red";
        } else if (errorColor === ErrorColorsEnum.ORANGE) {
            return "text-orange";
        } else if (errorColor === ErrorColorsEnum.YELLOW) {
            return "text-yellow";
        } else if (errorColor === ErrorColorsEnum.GREY) {
            return "text-darkGrey"
        }

        return "text-red";
    };

  const PlanElement: React.FC<PropsPlanElement> = ({
    positionNumber,
    stage,
    index,
  }) => {
    const { image, loading } = useImageFlag(
      !!stage ? stage.address.countryCode : ""
    );
    let startStopTime = null;
    if (
      !!activeData &&
      (index === 0 || index === activeData?.stops.length - 1)
    ) {
      startStopTime = !!stage.startTime ? stage.startTime : stage.stopTime;
    }

    return (
      <>
        {!!activeData && (
          <div className="flex items-center">
            <div className="flex-shrink-0 mr-[15px] h-6 w-6 bg-lightGrey rounded-full flex justify-center items-center text-[15px] font-bold leading-[24px]">
              {positionNumber}
            </div>

            <div className="grid grid-cols-3 gap-3">
              <div className="col-span-2 border border-lightGrey grow rounded-[4px] p-2 flex items-center">
                <div>
                  <div className="text-[13px] font-bold leading-[16px] flex items-center">
                    {!loading && (
                      <div className="mr-1 flex-shrink-0">
                        {/* @ts-ignore */}
                        <img src={image} alt="image" />
                      </div>
                    )}

                    <div className="flex-shrink">
                      {stage.address.type === "Deposit" ? "Cross-dock:" : ""}{" "}
                      {stage.address.invPartyName}
                    </div>
                  </div>

                  {stage.address.type !== "Deposit" && (
                    <div className="text-darkGrey text-xs">
                      {stage.address.addressLine}
                    </div>
                  )}
                </div>
              </div>

              <div className="col-span-1 border border-lightGrey text-[12px] grow rounded-[4px] p-2 flex flex-col items-center justify-center">
                {index === 0 || index === activeData?.stops.length - 1 ? (
                  <input
                    className="block w-full outline-none bg-transparent"
                    type="datetime-local"
                    placeholder="Pickup/Delivery"
                    // @ts-ignore
                    value={!!startStopTime? dayjs.utc(startStopTime).local().format("YYYY-MM-DDTHH:mm")
                        : ""
                    }
                    disabled={!canEditSelectedTrip}
                    onChange={(e) =>
                      handleChangeStartStopTime(
                        e.target.value,
                        index,
                        "startStop"
                      )
                    }
                  />
                ) : (
                  <>
                    <input
                      className="block w-full outline-none bg-transparent"
                      type="datetime-local"
                      placeholder="Pickup/Delivery"
                      // @ts-ignore
                      value={!!stage.startTime? dayjs.utc(stage.startTime).local().format("YYYY-MM-DDTHH:mm")
                          : ""
                      }
                      disabled={!canEditSelectedTrip}
                      onChange={(e) =>
                        handleChangeStartStopTime(
                          e.target.value,
                          index,
                          "start"
                        )
                      }
                    />

                    <input
                      className="block w-full outline-none bg-transparent"
                      type="datetime-local"
                      placeholder="Pickup/Delivery"
                      // @ts-ignore
                      value={!!stage.stopTime? dayjs.utc(stage.stopTime).local().format("YYYY-MM-DDTHH:mm")
                          : ""
                      }
                      disabled={!canEditSelectedTrip}
                      onChange={(e) =>
                        handleChangeStartStopTime(e.target.value, index, "stop")
                      }
                    />
                  </>
                )}
              </div>
            </div>

            {/* DRAG */}
            <div className="flex-shrink-0 ml-3">
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M4 9H20V11H4V9ZM4 13H20V15H4V13Z" fill="#6F6F6F" />
              </svg>
            </div>
          </div>
        )}
      </>
    );
  };

  const asideZIndex = useMemo(() => {
    return isOpenTripPlanner ? " z-[999]" : " z-[50]";
  }, [isOpenTripPlanner]);

  const [isSearchFiltersActive, setIsSearchFiltersActive] = useState<boolean>(
    false
  );

  const countActiveAdvFilters = useMemo(() => {
    return (
      Object.values(advancedFiltersRef.current).filter(
        (value) => value !== undefined
      ).length - 1
    );
  }, [advancedFiltersRef.current]);

  const [activeTripModalError, setActiveTripModalError] = useState<
    null | number
  >(null);

  const [selectedAction, setSelectedAction] = useState<string | null>(null);

  // Verifica dei permessi per ogni azione
  const canModifyPlanning = hasPermission(
    PermissionAreas.Planning,
    PermissionOperations.Modify
  );
  const canSendTrip = hasPermission(
    PermissionAreas.Planning,
    PermissionOperations.SendTrip
  );

  const actions: Action[] = [
    ...(canModifyPlanning
      ? [{ value: "takeInCharge", label: "Take in charge" }]
      : []),
    ...(canModifyPlanning
      ? [{ value: "revokeCharge", label: "Revoke charge" }]
      : []),
    { value: "setAsDispatchable", label: "Set as dispatchable" }, // Nessun controllo su permessi qui
    ...(canSendTrip
      ? [{ value: "sendImmediately", label: "Send immediately" }]
      : []),
  ];

  const handleTripSelection = (tripId: number) => {
    setSelectedTrips((prevSelectedTrips) => {
      if (prevSelectedTrips.includes(tripId)) {
        return prevSelectedTrips.filter((id) => id !== tripId);
      } else {
        return [...prevSelectedTrips, tripId];
      }
    });
  };

  const handleActionOnSelectedTrips = () => {
    if (selectedAction && selectedTrips.length > 0) {
      if (selectedAction === "setAsDispatchable") {
        _handleTripConfirmation();
      } else if (selectedAction === "revokeCharge") {
        if (!canModifyPlanning) {
          toast.error("You do not have permission to revoke charge of trips.");
          return;
        }
        _handleTripRevokeCharge();
      } else if (selectedAction === "takeInCharge") {
        if (!canModifyPlanning) {
          toast.error("You do not have permission to take charge of trips.");
          return;
        }
        _handleTripTakeCharge();
      } else if (selectedAction === "sendImmediately") {
        if (!canSendTrip) {
          toast.error("You do not have permission to send trips immediately.");
          return;
        }
        _handleTripSendImmediately();
      }
    }
  };

  const toLowerCaseColor = (color?: string | null): string => {
    return color?.toLowerCase() || "red"; // Converte in minuscolo o ritorna "red" se `color` è null o undefined
  };


  return (
    <>
      {isVisibleSidebarTrips && (
        <>
          {// Background to catch when user clicks outside the sidebar
          isOpenTripPlanner && (
            <div
              style={{
                zIndex: isOpenTripPlanner ? 800 : 0,
              }}
              className="fixed top-0 left-0 w-screen h-screen"
              onClick={checkSubmitSaveTrip}
            ></div>
          )}

          {/* {
                        !!tripDependenciesToShow &&
                        <ModalTripDependencies
                            callbackOnClose={() => setTripDependenciesToShow(undefined)}
                            tripDependenciesToShow={tripDependenciesToShow}
                        />
                    } */}

          {isOpenModalTripRules && (
            <ModalTripRules
              callbackOnClose={() => setIsOpenModalTripRules(false)}
            />
          )}

          {activeTripModalError !== null && (
            <ModalTripError
              callbackOnClose={() => setActiveTripModalError(null)}
              tripId={activeTripModalError}
            />
          )}

          <aside
            className={
              "flex flex-col transition fixed right-0 top-[130px] h-[calc(100vh-130px)] w-screen md:w-[92%] bg-white hide-scrollbar" +
              asideZIndex +
              (isOpen === true
                ? isOpenTripPlanner === true
                  ? " translate-x-0 sidebar-shadow"
                  : " translate-x-[65%] sidebar-shadow"
                : " translate-x-[100%]")
            }
          >
            {/* Badge trips */}
            {trips && (
              <div
                onClick={handleClose}
                style={{
                  cursor:
                    isOpenTripPlanner && isInModify.current
                      ? "default"
                      : "pointer",
                }}
                className="absolute top-0 left-0 -translate-x-full rounded-l-[4px] shadow-card bg-white uppercase font-bold px-[16px] bg-blue h-[110px]"
              >
                <div
                  className={
                    "absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 -rotate-90 flex items-center" +
                    (isOpen === false ? " text-blue" : " text-black")
                  }
                >
                  <svg
                    className="rotate-90"
                    width="24"
                    height="25"
                    viewBox="0 0 24 25"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill={isOpen ? "#333333" : "#3699FF"}
                      d="M8 5.35C7.69772 5.52451 7.44638 5.77509 7.27095 6.07683C7.09552 6.37857 7.00211 6.72097 7 7.07V9.5H5C4.73478 9.5 4.48043 9.60536 4.29289 9.79289C4.10536 9.98043 4 10.2348 4 10.5L4 20.5C4 21.0304 4.21071 21.5391 4.58579 21.9142C4.96086 22.2893 5.46957 22.5 6 22.5H16C16.3507 22.4996 16.6952 22.4071 16.9988 22.2315C17.3025 22.056 17.5546 21.8037 17.73 21.5C18.6583 21.5358 19.5627 21.2014 20.2444 20.5703C20.9261 19.9393 21.3292 19.0633 21.365 18.135C21.4008 17.2067 21.0664 16.3023 20.4353 15.6206C19.8043 14.9389 18.9283 14.5358 18 14.5V11.4C18.8367 11.2894 19.6047 10.8785 20.161 10.2439C20.7173 9.60919 21.024 8.79398 21.024 7.95C21.024 7.10602 20.7173 6.29081 20.161 5.65613C19.6047 5.02146 18.8367 4.61058 18 4.5C18 3.96957 17.7893 3.46086 17.4142 3.08579C17.0391 2.71071 16.5304 2.5 16 2.5H13C12.8176 2.50157 12.6386 2.54976 12.48 2.64L8 5.35ZM9 9.5V7.07L12 5.27L12 9.5H9ZM19 18C19 18.2967 18.912 18.5867 18.7472 18.8334C18.5824 19.08 18.3481 19.2723 18.074 19.3858C17.7999 19.4994 17.4983 19.5291 17.2074 19.4712C16.9164 19.4133 16.6491 19.2704 16.4393 19.0607C16.2296 18.8509 16.0867 18.5836 16.0288 18.2926C15.9709 18.0017 16.0007 17.7001 16.1142 17.426C16.2277 17.1519 16.42 16.9176 16.6666 16.7528C16.9133 16.588 17.2033 16.5 17.5 16.5C17.8978 16.5 18.2794 16.658 18.5607 16.9393C18.842 17.2206 19 17.6022 19 18ZM19 8C19 8.29667 18.912 8.58668 18.7472 8.83335C18.5824 9.08003 18.3481 9.27229 18.074 9.38582C17.7999 9.49935 17.4983 9.52906 17.2074 9.47118C16.9164 9.4133 16.6491 9.27044 16.4393 9.06066C16.2296 8.85088 16.0867 8.58361 16.0288 8.29263C15.9709 8.00166 16.0007 7.70006 16.1142 7.42598C16.2277 7.15189 16.42 6.91762 16.6666 6.7528C16.9133 6.58797 17.2033 6.5 17.5 6.5C17.8978 6.5 18.2794 6.65804 18.5607 6.93934C18.842 7.22064 19 7.60218 19 8Z"
                    />
                  </svg>
                  <div className="ml-1">Trips</div>
                </div>
              </div>
            )}

            {/* MAIN CONTENT OF SIDEBAR */}
            <div className="py-6 flex h-full relative">
              <div className="w-[35%] h-full">
                <div className="h-full flex flex-col  overflow-auto relative">
                  {// OVERLAY TO CATCH WHEN USER CLICKS OUTSIDE OF PLANNER WHEN NOT SAVED
                  isOpenTripPlanner && isInModify.current && (
                    <div
                      onClick={checkSubmitSaveTrip}
                      className="absolute w-full h-full z-50 top-0 left-0"
                      style={{
                        cursor:
                          isOpenTripPlanner && isInModify.current
                            ? "not-allowed"
                            : "unset",
                      }}
                    />
                  )}

                  <div
                    className="px-[30px]"
                    style={{
                      boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.2)",
                      zIndex: 300,
                    }}
                  >
                    {/* Header */}
                    <div className="w-full flex items-center justify-between">
                      <div className="text-blue text-underlined font-regular text-[12px]">
                        <span
                          className="cursor-pointer"
                          onClick={() => setIsOpenModalTripRules(true)}
                        >
                          View trip rules
                        </span>
                      </div>

                      <div
                        onClick={handleOpenCloseTripPlanner}
                        className="cursor-pointer flex items-center uppercase text-blue text-[15px] font-bold"
                      >
                        <div className="mr-1 select-none">Trip planner</div>
                        {!!activeTripId && (
                          <>
                            {isOpenTripPlanner === false && (
                              <svg
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M18 5.99997H20V18H18V5.99997ZM4 13H12.586L8.293 17.293L9.707 18.707L16.414 12L9.707 5.29297L8.293 6.70697L12.586 11H4V13Z"
                                  fill="#3987e2"
                                />
                              </svg>
                            )}

                            {isOpenTripPlanner === true && (
                              <svg
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M17.6569 16.2427L13.4142 12.0001L17.6569 7.75744L16.2426 6.34323L12 10.5859L7.75736 6.34323L6.34315 7.75744L10.5858 12.0001L6.34315 16.2427L7.75736 17.6569L12 13.4143L16.2426 17.6569L17.6569 16.2427Z"
                                  fill="#3987e2"
                                />
                              </svg>
                            )}
                          </>
                        )}
                      </div>
                    </div>

                    <div className="mt-[24px] mb-[12px] grid grid-cols-12 gap-[12px] items-end">
                      <div className="col-span-6 text-lg font-bold leading-[21.6px]">
                        <Select
                          options={tripFilterOptions}
                          value={currentTripOptionValueRef.current}
                          callbackOnChange={(e) => {
                            handleChangeTripOptionValue(e.target.value);
                            setSelectedTrips([]);
                          }}
                          label="Trips"
                          name="tripsOptionValue"
                        />
                      </div>

                      <div className="col-span-6">
                        <InputText
                          callbackOnChange={(e: any) => {
                            const obj: AdvancedFiltersType = {
                              ...advancedFiltersRef.current,
                            };
                            obj["tripDate"] = e !== "" ? e : undefined;
                            handleChangeTripAdvancedFilters(obj);
                            setSelectedTrips([]);
                          }}
                          name="trip_creation"
                          placeholder="gg/mm/aaaa"
                          label="Trip creation"
                          value={
                            !!advancedFiltersRef.current?.tripDate
                              ? advancedFiltersRef.current.tripDate
                              : ""
                          }
                          type="date"
                        />
                      </div>
                    </div>

                    <div className="w-full flex justify-end py-[10px] mb-[12px] select-none">
                      <div
                        onClick={() =>
                          setIsSearchFiltersActive(!isSearchFiltersActive)
                        }
                        className="cursor-pointer flex items-center"
                      >
                        <div className="mr-2 text-[13px] font-bold text-blue uppercase">
                          Advanced Filter ({countActiveAdvFilters})
                        </div>
                        <div>
                          {isSearchFiltersActive && (
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              strokeWidth={1.5}
                              className="w-5 h-5 stroke-blue"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M6 18 18 6M6 6l12 12"
                              />
                            </svg>
                          )}

                          {!isSearchFiltersActive && (
                            <svg
                              width="16"
                              height="16"
                              viewBox="0 0 16 16"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M4.66663 7.33464H11.3333V8.66797H4.66663V7.33464ZM2.66663 4.66797H13.3333V6.0013H2.66663V4.66797ZM6.66663 10.0013H9.33329V11.3346H6.66663V10.0013Z"
                                fill="#3987E2"
                              />
                            </svg>
                          )}
                        </div>
                      </div>
                    </div>

                    {isSearchFiltersActive && (
                      <div className="mb-6">
                        <AdvancedFilter
                          advancedFilters={advancedFiltersRef.current}
                          callbackSelect={(filters) =>
                            handleChangeTripAdvancedFilters(filters)
                          }
                        />
                      </div>
                    )}
                    {!!trips && actualUser?.name !== 'Guest' && (
                      <SelectedTripsComponent
                        selectedTrips={selectedTrips}
                        actions={actions}
                        selectedAction={selectedAction}
                        setSelectedAction={setSelectedAction}
                        handleActionOnSelectedTrips={
                          handleActionOnSelectedTrips
                        }
                      />
                    )}
                  </div>

                  {/* TRIPS & TRIPS CARDS */}
                  <div ref={tripsContainerRef} className="grow overflow-y-auto">
                    {!!trips && (
                      <>
                        {trips?.length > 0 && (
                          <>
                            {trips.map((trip, index) => {
                              return (
                                <div
                                  key={trip.id}
                                  className="flex-col items-start  "
                                >
                                  {/* Aggiungi la checkbox per la selezione multipla */}
                                  <div
                                    style={{
                                      display: "flex",
                                      flexDirection: "row",
                                      width: "100%",
                                      alignItems: "center",
                                      columnGap: "8px",
                                      padding: "24px 8px",
                                    }}
                                  >
                                     {actualUser?.name !== 'Guest' && (
                                    <div
                                      className="flex items-center"
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        padding: "4px",
                                      }}
                                    >
                                      <label className="relative">
                                        <input
                                          type="checkbox"
                                          checked={selectedTrips.includes(
                                            trip.id
                                          )}
                                          onChange={() =>
                                            handleTripSelection(trip.id)
                                          }
                                          className="cursor-pointer appearance-none w-8 h-8 rounded-full checked:border-transparent focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50"
                                          style={{
                                            outline: "none",
                                            border: selectedTrips.includes(
                                              trip.id
                                            )
                                              ? "2px solid transparent"
                                              : "1px solid gray",
                                            transition:
                                              "background-color 0.2s, border-color 0.2s",
                                            backgroundColor: selectedTrips.includes(
                                              trip.id
                                            )
                                              ? "rgb(57, 135, 226)"
                                              : "#FFFFFF",
                                          }}
                                        />

                                        {selectedTrips.includes(trip.id) && (
                                          <svg
                                            className="absolute top-0 left-0 w-8 h-8 pointer-events-none"
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 24 24"
                                            fill="none"
                                            stroke="white"
                                            strokeWidth="2"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                          >
                                            <path d="M5 13l4 4L19 7" />
                                          </svg>
                                        )}
                                      </label>
                                    </div>
                                     )}
                                    <div
                                      onClick={() =>
                                        setActiveTripId(
                                          trip?.id === activeTripId
                                            ? null
                                            : trip?.id
                                        )
                                      }
                                      key={index}
                                      className={
                                        "border-[1px] relative cursor-pointer p-[24px] rounded-[8px] w-full" +
                                        (activeTripId == trip.id
                                          ? " border-blue bg-lightGrey"
                                          : " border-lightGrey bg-lightGrey2")
                                      }
                                    >
                                      <div className="w-full flex justify-between pb-[14px]">
                                        <div className="flex items-center">
                                          <div className="text-[13px] text-blue font-bold uppercase">
                                            TRIP DATE:
                                          </div>
                                          <div className="font-bold text-[12px] ml-2">
                                            {dayjs(trip?.creationTime).format(
                                              "DD/MM/YYYY"
                                            )}
                                          </div>
                                        </div>

                                        {!!trip?.sgaTripId &&
                                          !!trip.status &&
                                          trip.status.id !==
                                            TripStatusEnum.NOT_DISPATCHABLE &&
                                          trip.status.id !==
                                            TripStatusEnum.DISPATCHABLE && (
                                            <div className="flex items-center">
                                              <div className="text-[13px] text-blue font-bold uppercase">
                                                TMS TRIP NUMBER:
                                              </div>
                                              <div className="font-bold text-[12px] ml-2">
                                                {trip.sgaTripId}
                                              </div>
                                            </div>
                                          )}
                                      </div>

                                      <div className="flex justify-between items-stretch">
                                        <div className="flex items-stretch">
                                          <div className="mr-6 flex flex-col justify-between">
                                            <div className="text-[13px] text-blue font-bold uppercase">
                                              Trip id
                                            </div>
                                            <div className="flex flex-col justify-between">
                                              <div className="flex items-center">
                                                <div>
                                                  <div
                                                    className="text-[32px] font-bold"
                                                    title={
                                                      /*hasPermission(PermissionAreas.DeveloperOperations, PermissionOperations.TestDebugActions) ? */ "Trip id: " +
                                                      trip?.id /*: undefined*/
                                                    }
                                                  >
                                                    {trip?.dailyId}
                                                  </div>
                                                </div>
                                                <div>
                                                  {!!trip?.status &&
                                                    trip?.status?.id !==
                                                      TripStatusEnum.NOT_DISPATCHABLE &&
                                                    trip?.status?.id !==
                                                      TripStatusEnum.REPLANNING && (
                                                      <div className="ml-1">
                                                        <svg
                                                          width="18"
                                                          height="18"
                                                          viewBox="0 0 18 18"
                                                          fill="none"
                                                          xmlns="http://www.w3.org/2000/svg"
                                                        >
                                                          <rect
                                                            width="18"
                                                            height="18"
                                                            rx="9"
                                                            fill="#60C589"
                                                          />
                                                          <path
                                                            d="M13.6673 6.08398L7.25065 12.5007L4.33398 9.58398"
                                                            stroke="white"
                                                            strokeWidth="2"
                                                            strokeLinecap="round"
                                                            strokeLinejoin="round"
                                                          />
                                                        </svg>
                                                      </div>
                                                    )}
                                                </div>
                                              </div>
                                            </div>
                                          </div>

                                          <div className="flex flex-col justify-between" >
                                            <div className="text-[13px] text-blue font-bold mb-3 uppercase">
                                              Volume
                                            </div>
                                            <div className="font-bold text-[32px]">
                                              {trip?.volume.toFixed(2)} M³
                                            </div>
                                          </div>
                                        </div>

                                        <div className="flex flex-col justify-between" >
                                          <div className="text-[13px] text-blue font-bold mb-3 uppercase">
                                            Equipment
                                          </div>
                                          {trip?.equipment?.id === 1 && (
                                            <IconCamionS />
                                          )}
                                          {(trip?.equipment?.id === 2 ||
                                            trip?.equipment?.id === 3) && (
                                            <IconCamionM />
                                          )}
                                        </div>
                                      </div>

                                      <div className="py-[14px]" >
                                        <div className="flex items-center justify-between">
                                          <div>
                                            <div className="font-bold text-xs uppercase mb-1">
                                              Weight
                                            </div>
                                            <div className="text-xs uppercase">
                                              {trip?.weight} kg
                                            </div>
                                          </div>
                                          <div>
                                            <div className="font-bold text-xs uppercase mb-1">
                                              tax-Weight
                                            </div>
                                            <div className="text-xs uppercase">
                                              {trip?.taxWeight} kg
                                            </div>
                                          </div>
                                          <div>
                                            <div className="font-bold text-xs uppercase mb-1">
                                              Linear meters
                                            </div>
                                            <div className="text-xs uppercase">
                                              {trip?.linearMeters}
                                            </div>
                                          </div>
                                          <div>
                                            <div className="font-bold text-xs uppercase mb-1">
                                              Shipping Units
                                            </div>
                                            <div className="text-xs uppercase">
                                              {trip?.shipmentUnit}
                                            </div>
                                          </div>
                                        </div>

                                        {!!trip?.description && (
                                          <div className="flex mt-[14px] overflow-hidden whitespace-nowrap">
                                            <p className="text-[13px] text-darkGrey mb-0 overflow-hidden text-ellipsis">
                                              {trip?.description}
                                            </p>
                                          </div>
                                        )}
                                      </div>

                                      <div className="mb-[14px] flex items-end justify-between border-t border-[#E9E8E8] pt-[14px]">
                                        <div className="font-bold text-[11px] text-[#6F6F6F]">
                                          {trip?.owner !== null && (
                                            <div>
                                              Owned by{" "}
                                              {`${trip.owner?.name} ${trip.owner?.surname}`}
                                            </div>
                                          )}
                                          {trip?.owner === null && (
                                            <div>Trip not in charge</div>
                                          )}
                                        </div>

                                        {/* Presa in carico */}
                                        {hasPermission(
                                          PermissionAreas.Planning,
                                          PermissionOperations.Modify
                                        ) &&
                                          (trip?.owner?.username ===
                                            actualUser?.username ||
                                            trip?.owner === null) && (
                                            <div>
                                              <div
                                                onClick={(e) =>
                                                  handleTripCharge(e, trip)
                                                }
                                                className="w-max cursor-pointer flex items-center p-2 border-[1.4px] border-blue rounded-[4px]"
                                              >
                                                <label
                                                  className="cursor-pointer mb-0 text-blue select-none text-[13px] leading-[16px] font-semibold uppercase"
                                                  htmlFor="trip_plan_charge"
                                                >
                                                  In charge
                                                </label>
                                                {(trip?.owner === null ||
                                                  trip?.owner?.username ===
                                                    actualUser?.username) && (
                                                  <div className="cursor-pointer ml-[6px] flex items-center">
                                                    <input
                                                      onChange={() => {}}
                                                      className="cursor-pointer"
                                                      type="checkbox"
                                                      name="trip_plan_charge_card"
                                                      id="trip_plan_charge_card"
                                                      checked={
                                                        trip?.owner
                                                          ?.username ===
                                                        actualUser?.username
                                                      }
                                                      disabled={
                                                        trip?.status?.id ===
                                                          TripStatusEnum.DISPATCHING ||
                                                        trip?.status?.id ===
                                                          TripStatusEnum.DEPARTED
                                                      }
                                                    />
                                                  </div>
                                                )}
                                              </div>
                                            </div>
                                          )}
                                      </div>

                                      <div className="flex items-start justify-between text-xs uppercase">
                                        <div className="w-1/2">
                                          <div className="font-bold mb-1">
                                            CUTOFF TIME:
                                          </div>
                                          {!!trip?.cutoff && (
                                            <div>{`${
                                              trip?.cutoff.split(":")[0]
                                            }:${
                                              trip?.cutoff.split(":")[1]
                                            }`}</div>
                                          )}
                                          {!trip?.cutoff && <span>-</span>}
                                        </div>

                                        <div className="w-1/2">
                                          <div className="font-bold mb-1">
                                            STATUS:
                                          </div>
                                          {trip?.status?.id !==
                                            TripStatusEnum.ERROR &&
                                            trip?.status?.id !==
                                              TripStatusEnum.HANDLED_ERROR && (
                                              <div>{trip?.status?.name}</div>
                                            )}
                                          {(trip?.status?.id ===
                                            TripStatusEnum.ERROR ||
                                            trip?.status?.id ===
                                              TripStatusEnum.HANDLED_ERROR) && (
                                            <div className="flex items-center">
                                              <svg
                                                className={`text-${toLowerCaseColor(
                                                  trip?.errorColor
                                                )}`}
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="1.6em"
                                                height="1.6em"
                                                viewBox="0 0 24 24"
                                              >
                                                <path
                                                  fill="currentColor"
                                                  d="M12.003 21q-1.866 0-3.51-.708q-1.643-.709-2.859-1.924q-1.216-1.214-1.925-2.856Q3 13.87 3 12.003q0-1.866.708-3.51q.709-1.643 1.924-2.859q1.214-1.216 2.856-1.925Q10.13 3 11.997 3q1.866 0 3.51.708q1.643.709 2.859 1.924q1.216 1.214 1.925 2.856Q21 10.13 21 11.997q0 1.866-.708 3.51q-.709 1.643-1.924 2.859q-1.214 1.216-2.856 1.925Q13.87 21 12.003 21"
                                                />
                                              </svg>
                                              <div
                                                onClick={(e) => {
                                                  e.stopPropagation();
                                                  e.preventDefault();
                                                  setActiveTripModalError(
                                                    trip?.id
                                                  );
                                                }}
                                                className="ml-2 text-blue text-underlined cursor-pointer"
                                              >
                                                {trip?.status?.name}
                                              </div>
                                            </div>
                                          )}
                                        </div>
                                        {/* <div>
                                                                                <div className="font-bold mb-1">DEPENDENCIES:</div>
                                                                                {
                                                                                    !!trip?.dependencies &&
                                                                                    <div className="flex items-center">
                                                                                        <svg className={(trip.dependencies.trips?.length > 0 || trip.dependencies.orders?.length > 0) ? "text-red" : "text-green"} xmlns="http://www.w3.org/2000/svg" width="1.6em" height="1.6em" viewBox="0 0 24 24"><path fill="currentColor" d="M12.003 21q-1.866 0-3.51-.708q-1.643-.709-2.859-1.924q-1.216-1.214-1.925-2.856Q3 13.87 3 12.003q0-1.866.708-3.51q.709-1.643 1.924-2.859q1.214-1.216 2.856-1.925Q10.13 3 11.997 3q1.866 0 3.51.708q1.643.709 2.859 1.924q1.216 1.214 1.925 2.856Q21 10.13 21 11.997q0 1.866-.708 3.51q-.709 1.643-1.924 2.859q-1.214 1.216-2.856 1.925Q13.87 21 12.003 21" /></svg>
                                                                                        <div onClick={(e) => { e.stopPropagation(); e.preventDefault(); setTripDependenciesToShow(trip.dependencies); }} className="ml-2 text-blue text-underlined cursor-pointer">View</div>
                                                                                    </div>
                                                                                }
                                                                                {
                                                                                    !trip?.dependencies &&
                                                                                    <span>-</span>
                                                                                }
                                                                            </div> */}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              );
                            })}
                          </>
                        )}

                        {loadingTripsRef.current === false &&
                          trips.length === 0 && (
                            <p>
                              There are no trips for the requested parameters.
                            </p>
                          )}
                      </>
                    )}

                    {loadingTripsRef.current === true && (
                      <div className="flex justify-center mt-2">
                        <Spinner />
                      </div>
                    )}
                  </div>
                </div>
              </div>

              {/* TRIP PLANNER */}
              <div
                ref={containerActiveTrip}
                className="w-[65%] px-[30px] overflow-auto"
              >
                {!!activeData && (
                  <>
                    <div className="border-blue border rounded-[10px]">
                      <div className="flex items-center">
                        <div className="p-3">
                          <div className="ml-[36px] uppercase text-blue font-bold text-[13px]">
                            Trip id{" "}
                            <span className="text-black ml-3">
                              {activeData?.dailyId} -{" "}
                              {dayjs(activeData.creationTime).format(
                                "DD/MM/YYYY"
                              )}
                            </span>
                          </div>
                        </div>
                        {!!activeData.sgaTripId &&
                          !!activeData.status &&
                          activeData.status.id !==
                            TripStatusEnum.NOT_DISPATCHABLE &&
                          activeData.status.id !==
                            TripStatusEnum.DISPATCHABLE && (
                            <div className="p-3">
                              <div className="uppercase text-blue font-bold text-[13px]">
                                TMS Trip number{" "}
                                <span className="text-black ml-3">
                                  {activeData.sgaTripId}
                                </span>
                              </div>
                            </div>
                          )}
                      </div>

                      {// ACTION BUTTONS
                      !!isUserInCharge && (
                        <div className="p-3 ml-[36px] pt-0 flex flex-wrap">
                          <div className="pr-4">
                            <Button
                              callback={handleTripSave}
                              disabled={!isInModify.current}
                            >
                              Save
                            </Button>
                          </div>

                          {!!activeTrip?.status &&
                            activeTrip.status?.id ===
                              TripStatusEnum.NOT_DISPATCHABLE && (
                              <div className="pr-4">
                                <Button
                                  callback={handleDeleteTrip}
                                  disabled={activeTrip?.legs.some(
                                    (x) => x.planned
                                  )}
                                >
                                  Delete
                                </Button>
                              </div>
                            )}

                          {activeTrip?.status?.id === TripStatusEnum.DISPATCHABLE && activeTrip.sendImmediatly == false && 
                              <div className="pr-4">
                                <Button
                                  callback={handleTripSendImmediately}
                                  disabled={!hasPermission( PermissionAreas.Planning, PermissionOperations.SendTrip)}
                                >
                                  Send immediately
                                </Button>
                              </div>
                            }


                          {/* SET AS DISPATCHABLE */}
                          <div className="pr-4">
                            <div className="w-max flex items-center p-2 border-[1.4px] border-blue rounded-[4px] h-[37.2px]">
                              <label
                                className="mb-0 text-blue select-none text-[13px] leading-[16px] font-semibold uppercase"
                                htmlFor="trip_plan_confirmation"
                              >
                                Set as dispatchable
                              </label>
                              <div className="ml-[6px] flex items-center">
                                <input
                                  type="checkbox"
                                  name="trip_plan_confirmation"
                                  id="trip_plan_confirmation"
                                  checked={
                                    !!activeTrip?.status &&
                                    activeTrip.status?.id !==
                                      TripStatusEnum.NOT_DISPATCHABLE &&
                                    activeTrip?.status.id !==
                                      TripStatusEnum.REPLANNING
                                  }
                                  disabled={
                                    isInModify.current ||
                                    (!!activeTrip?.status &&
                                      activeTrip?.status.id !==
                                        TripStatusEnum.NOT_DISPATCHABLE &&
                                      activeTrip?.status.id !==
                                        TripStatusEnum.REPLANNING)
                                  }
                                  onChange={(e) =>
                                    handleTripConfirmation(e.target.checked)
                                  }
                                />
                              </div>
                            </div>
                          </div>

                          {!!activeTrip?.status &&
                            (activeTrip?.status.id ===
                              TripStatusEnum.DISPATCHED ||
                              activeTrip?.status.id ===
                                TripStatusEnum.ERROR) && (
                              <div className="pr-4">
                                <Button callback={handleTripReplan}>
                                  Replan
                                </Button>
                              </div>
                            )}
                        </div>
                      )}

                      <div className="grid grid-cols-2 gap-4 mt-3 p-2">
                        <div>
                          {/* Stops droppable */}
                          <DragDropContext onDragEnd={handleDrop}>
                            <Droppable
                              isDropDisabled={!canEditSelectedTrip}
                              droppableId="list-container"
                            >
                              {(provided: any) => (
                                <div
                                  className="p-3 pb-0 pt-2"
                                  {...provided.droppableProps}
                                  ref={provided.innerRef}
                                >
                                  {activeData.stops.map((stage, index) => {
                                    return (
                                      <Draggable
                                        key={stage.address.id}
                                        draggableId={stage.address.id.toString()}
                                        index={index}
                                      >
                                        {(provided: any) => (
                                          <div
                                            className="mb-2 select-none draggable"
                                            ref={provided.innerRef}
                                            {...provided.dragHandleProps}
                                            {...provided.draggableProps}
                                          >
                                            <PlanElement
                                              index={index}
                                              positionNumber={index + 1}
                                              stage={stage}
                                            />
                                          </div>
                                        )}
                                      </Draggable>
                                    );
                                  })}
                                  {provided.placeholder}
                                </div>
                              )}
                            </Droppable>
                          </DragDropContext>

                          {stopOrderIssues.length > 0 && (
                            <div className="border border-blue rounded p-2 m-2">
                              <div className="flex justify-center">
                                <div>
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    strokeWidth="1.5"
                                    stroke="currentColor"
                                    className="w-6 h-6 text-blue"
                                  >
                                    <path
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126ZM12 15.75h.007v.008H12v-.008Z"
                                    />
                                  </svg>
                                </div>
                                <div className="pl-2">
                                  <p className="text-blue font-bold">
                                    The stop order is incorrect!
                                  </p>
                                </div>
                              </div>

                              <div className="p-3 pt-0 justify-center">
                                {stopOrderIssues.map((issue, index) => {
                                  return <p key={index}>{issue}</p>;
                                })}
                              </div>
                            </div>
                          )}
                        </div>

                        <div>
                          {/* MAP */}
                          {!!activeData.stops && activeData.stops.length > 0 && (
                            <div>
                              <SidebarTripsMap
                                stops={activeData.stops}
                                handleCallbackFullScreen={() =>
                                  setIsMapFullScreen(!isMapFullScreen)
                                }
                                isFullScreen={isMapFullScreen}
                              />
                            </div>
                          )}
                        </div>
                      </div>

                      <div className="px-3 py-6 border-t border-b select-none">
                        <div className="text-[15px] font-semibold">
                          Add plate and info
                        </div>
                        <div className="mt-6">
                          <div>
                            <label
                              className="font-semibold text-sm text-blue uppercase leading-[17px] block mb-[5px]"
                              htmlFor={"tripDescription"}
                            >
                              Description
                              <span className="text-red ml-1">*</span>
                            </label>
                            <textarea
                              onChange={(e) =>
                                handleChangeTripDescription(e.target.value)
                              }
                              value={
                                !!activeData.description
                                  ? activeData.description
                                  : ""
                              }
                              name="tripDescription"
                              id="tripDescription"
                              className={
                                "w-full outline-none text-[15px] border rounded-[4px] min-h-[80px] p-2" +
                                (tripErrors.description
                                  ? " !border-red"
                                  : " border-black") +
                                (!canEditSelectedTrip
                                  ? " cursor-not-allowed bg-[#fafafa]"
                                  : "")
                              }
                              disabled={!canEditSelectedTrip}
                              onFocus={() => resetTripError("description")}
                            />
                          </div>

                          <div className="grid grid-cols-3 mt-6 gap-6">
                            <div>
                              <Select
                                callbackOnChange={(e) =>
                                  handleChangeEquipment(e.target.value)
                                }
                                options={equipmentsOptions}
                                value={activeData.equipment?.id}
                                label="Equipment"
                                isRequired={true}
                                name="equipment"
                                placeholder="Insert equipment"
                                disabled={!canEditSelectedTrip}
                                hasError={tripErrors.equipment}
                                callbackOnFocus={() =>
                                  resetTripError("equipment")
                                }
                              />
                            </div>

                            <div>
                              <Select
                                callbackOnChange={(e) =>
                                  handleChangeTransportMode(e.target.value)
                                }
                                options={transportModeOptions}
                                value={
                                  !!transportModeOptions.some(
                                    (tM) =>
                                      tM.label === activeData.transportMode
                                  )
                                    ? transportModeOptions.find(
                                        (tM) =>
                                          tM.label === activeData.transportMode
                                      )?.value
                                    : ""
                                }
                                label="Transport Mode"
                                isRequired={true}
                                name="transportMode"
                                placeholder="Insert transport mode"
                                disabled={!canEditSelectedTrip}
                                defaultOptionEnabled={true}
                                hasError={tripErrors.transportMode}
                                callbackOnFocus={() =>
                                  resetTripError("transportMode")
                                }
                              />
                            </div>

                            <div>
                              <SelectAsyncPreference
                                initialOptions={carriers}
                                value={activeData.carrier}
                                label="Carriers"
                                name="vector1"
                                placeholder="Insert carrier"
                                disabled={!canEditSelectedTrip}
                                callbackOnSelect={(e) => handleChangeCarrier(e)}
                                apiServiceSearch={
                                  masterDataService.searchCarriers
                                }
                                apiServiceAddRemoveToFavourites={
                                  masterDataService.addRemoveCarrierToFavourites
                                }
                                paginated={true}
                                paginationCount={25}
                                dropDownPositionY="top"
                                callbackOnAddedToFavourites={updateCarriers}
                                callbackOnRemovedFromFavourites={updateCarriers}
                              />
                            </div>
                          </div>

                          <div className="mt-6 grid grid-cols-12 gap-6">
                            <div className="col-span-6">
                              <SelectAsyncPreference
                                initialOptions={trucks}
                                value={
                                  !!activeData.truckPlateNumber
                                    ? ({
                                        id: activeData.truckPlateNumber.id,
                                        name: activeData.truckPlateNumber.name,
                                      } as ResultType)
                                    : null
                                }
                                label="Truck plate number"
                                name="truk_plate_number"
                                placeholder="Insert truck plate number"
                                disabled={!canEditSelectedTrip}
                                callbackOnSelect={(e) =>
                                  handleChangeTripTruckTrailerPlate(e, "truck")
                                }
                                apiServiceSearch={masterDataService.searchTruck}
                                apiServiceAddRemoveToFavourites={
                                  masterDataService.addRemoveTruckToFavourites
                                }
                                paginated={true}
                                paginationCount={25}
                                dropDownPositionY="top"
                                callbackOnAddedToFavourites={updateVehicles}
                                callbackOnRemovedFromFavourites={updateVehicles}
                              />
                            </div>

                            <div className="col-span-6">
                              <SelectAsyncPreference
                                initialOptions={trailers}
                                value={
                                  !!activeData.truckPlateTrailer
                                    ? ({
                                        id: activeData.truckPlateTrailer.id,
                                        name: activeData.truckPlateTrailer.name,
                                      } as ResultType)
                                    : null
                                }
                                name="truk_plate_trailer"
                                label="Trailer plate number"
                                placeholder="Insert trailer plate number"
                                disabled={!canEditSelectedTrip}
                                callbackOnSelect={(e) =>
                                  handleChangeTripTruckTrailerPlate(
                                    e,
                                    "trailer"
                                  )
                                }
                                apiServiceSearch={
                                  masterDataService.searchTrailer
                                }
                                apiServiceAddRemoveToFavourites={
                                  masterDataService.addRemoveTrailerToFavourites
                                }
                                paginated={true}
                                paginationCount={25}
                                dropDownPositionY="top"
                                callbackOnAddedToFavourites={updateVehicles}
                                callbackOnRemovedFromFavourites={updateVehicles}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
          </aside>
        </>
      )}
    </>
  );
};

export default SidebarTrips;
